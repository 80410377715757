import React, { useState, useEffect } from "react";
import * as factors from "../database/factors";

const RiskFactorsModal = ({
  isVisible,
  onClose,
  riskFactors,
  onSelect,
  selectedRiskFactors,
  selectedAge, // Default age set to 50
}) => {
  const [selectedFactor, setSelectedFactor] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (selectedRiskFactors == selectedItems) {
      return;
    } else {
      setSelectedItems(selectedRiskFactors);
    }
  }, [selectedRiskFactors]);

  const handleSubmit = () => {
    onSelect(selectedItems);
    onClose();
  };

  const getContent = (factor, age) => {
    if (factor === "Wirbelfraktur(en)") {
      // Construct the variable name dynamically based on age
      const factorName = `Wirbelfrakturen${age}`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    } else if (factor === "Hüftfraktur und andere Frakturen") {
      // Construct the variable name dynamically based on age
      const factorName = `HüftfrakturUndAndereFrakturen${age}`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    } else if (factor === "Allgemeine Risikofaktoren") {
      const factorName = `AllgemeineRisikofaktoren${age}`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    } else if (factor === "Rheumatologie und Glukokortikoide") {
      const factorName = `RheumatologieUndGlukokortikoide${age}`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    } else if (factor === "Indikationen für Basisdiagnostik") {
      const factorName = `medikationenOhne`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    } else if (factor === "Sturzrisiko assoziierte Risikofaktoren/Geriatrie") {
      const factorName = `SturzrisikoassoziierteRisikofaktoren${age}`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    } else if (factor === "Endokrinologie") {
      const factorName = `Endokrinologie${age}`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    } else if (factor === "Weitere Erkrankungen/ Medikationen") {
      const factorName = `WeitereErkrankungen${age}`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    } else if (factor === "Trabecular Bone Score (TBS)") {
      const factorName = `TBS${age}`;
      // Check if the factor exists in the imported module
      if (factors[factorName]) {
        return factors[factorName];
      }
    }
    // Implement other cases as needed
    return null;
  };

  const toggleSelection = (item) => {
    setSelectedItems((prevItems) => {
      const isSingleSelection = [0, 10, 20, 30, 40, 50, 60, 70, 80].includes(
        item.mark
      );

      if (isSingleSelection) {
        const existingItemIndex = prevItems.findIndex(
          (prevItem) => prevItem.mark === item.mark
        );

        if (existingItemIndex !== -1) {
          // If the item exists, deselect it by removing it
          const updatedItems = [...prevItems];
          updatedItems.splice(existingItemIndex, 1);
          return updatedItems;
        } else {
          // Add the item if it's not already selected
          return [...prevItems, item];
        }
      } else {
        const itemIndex = prevItems.findIndex(
          (prevItem) => prevItem.id === item.id
        );

        if (itemIndex !== -1) {
          // Deselect by removing the item
          const updatedItems = [...prevItems];
          updatedItems.splice(itemIndex, 1);
          return updatedItems;
        } else {
          // Add the item if not selected
          return [...prevItems, item];
        }
      }
    });
  };

  const renderContent = () => {
    const content = getContent(selectedFactor, selectedAge);
    if (!content) {
      return <p>No data available for the selected factor.</p>;
    }

    return (
      <div>
        <button onClick={() => setShowContent(false)} className="back-button">
          &#x2190; Zurück
        </button>
        <h2>{content[0].title}</h2>
        <p>{content[0].subTitle}</p>
        <div className="content-container">
          <div className="content-table">
            {content[0].data.map((item) => (
              <div
                className="content-row"
                onClick={
                  typeof item.value === "number"
                    ? () => toggleSelection(item)
                    : null
                }
              >
                <div className="content-cell label">{item.label}</div>
                <div className="content-cell value">
                  {typeof item.value === "number"
                    ? item.value.toFixed(2)
                    : item.value}
                </div>
                {typeof item.value === "number" && (
                  <input
                    type="checkbox"
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id
                    )}
                    onChange={() => toggleSelection(item)}
                    className="content-checkbox"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleFactorSelect = (factor) => {
    if (factor === "Weiter") {
      handleSubmit(selectedItems); // Close the modal
    } else {
      setSelectedFactor(factor);
      setShowContent(true); //
    }
  };

  if (!isVisible) {
    return null;
  }

  const splitFactors = (factors) => {
    const half = Math.ceil(factors.length / 2);
    return [factors.slice(0, half), factors.slice(half)];
  };

  const [leftColumn, rightColumn] = splitFactors(riskFactors);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {showContent ? ( // Show detailed content if showContent is true
          renderContent()
        ) : (
          <>
            <h2>Risikofaktoren</h2>
            <div className="risk-factors">
              <div className="risk-column">
                {leftColumn.map((factor) => (
                  <button
                    key={factor}
                    onClick={() => handleFactorSelect(factor)}
                    className={`risk-button ${
                      factor === "Weiter" ? "weiter" : ""
                    } ${
                      factor === "Indikationen für Basisdiagnostik"
                        ? "special"
                        : ""
                    }`}
                  >
                    {factor}
                  </button>
                ))}
              </div>
              <div className="risk-column">
                {rightColumn.map((factor) => (
                  <button
                    key={factor}
                    onClick={() => handleFactorSelect(factor)}
                    className={`risk-button ${
                      factor === "Weiter" ? "weiter" : ""
                    } ${
                      factor === "Indikationen für Basisdiagnostik"
                        ? "special"
                        : ""
                    }`}
                  >
                    {factor}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RiskFactorsModal;
